// 請款資料認證狀態
export enum MonetizationStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

// 提領狀態
export enum WithdrawalStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REVIEWING = 'REVIEWING',
  SUCCESS = 'SUCCESS',
}

export interface WithdrawalInformation {
  // 可提領金額 (取銷售＆廣告整數的總和)
  amount: number
  // 累計可領收益 (銷售和廣告收益總和)
  withdrawable: string
  status: MonetizationStatus | null
}

export interface WithdrawalRecord {
  amount: number
  createdAt: string
  transferTime: string
  withdrawalStatus: WithdrawalStatus
}

export interface DailyRevenue {
  date: string // yyyy-mm-dd
  revenueFromAds: number // 廣告收益
  revenueFromPurchases: number // 解鎖點數收益
  revenueFromRentals: number // 限時點數收益
  revenueFromGifts: number // 禮物收益
  total: number // 總計
}
