import { Genre } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得作品類別
 * @param isRestricted 是否過濾百合、BL
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Genre/get_v2_genres
 */
export const getGenres = async (params: { isRestricted: boolean }): Promise<Genre[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.GENRES,
    params,
  })
  return res.data
}
