import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const agreeCreatorTerms = async (): Promise<{}> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.AGREE_CREATOR_TERMS,
  })
  return res.data
}
