export const gray = [
  '#ffffff', //gray.0
  '#f7fafc', //gray.1
  '#edf2f7', //gray.2
  '#e2e8f0', //gray.3
  '#cbd5e0', //gray.4
  '#a0aec0', //gray.5
  '#718096', //gray.6
  '#4a5568', //gray.7
  '#2d3748', //gray.8
  '#1a202c', //gray.9
]
