import { MyOverview } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得我的總覽
 */
export const getMyOverview = async (): Promise<MyOverview> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.MY_OVERVIEW,
  })
  return res.data
}
