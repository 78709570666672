/** @jsxImportSource theme-ui */
import { routes } from '@/constants/routes'
import { useStateInMounted } from '@/hooks/useStateInMounted'
import { getKadoDomain, IS_CLIENT_SIDE, pxToRem } from '@/utils'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useEffect } from 'react'
import { Flex, Grid, Text } from 'theme-ui'

const originalLinks = [
  {
    displayName: '成為創作者',
    href: 'https://event.kadokado.com.tw/tutorial/index.html',
    isNewTab: true,
  },
  { displayName: '使用條款', href: routes.TERMS, isNewTab: false },
  {
    displayName: '常見問題',
    href: 'https://support.kadokado.com.tw/hc/zh-tw/categories/5873970844057',
    isNewTab: true,
  },
]

const Footer = () => {
  const router = useRouter()
  const [links, setLinks] = useStateInMounted(originalLinks)

  useEffect(() => {
    if (IS_CLIENT_SIDE) {
      const tempLinks = [...links]

      tempLinks.splice(1, 0, {
        displayName: '隱私政策',
        href: `${getKadoDomain()}/privacypolicy`,
        isNewTab: true,
      })

      setLinks(tempLinks)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex
      as="footer"
      variant="superCenter"
      backgroundColor="gray.8"
      px={3}
      sx={{ flexDirection: 'column' }}
    >
      {router.pathname === routes.HOME && (
        <Grid mt={3} columns={2} gap={0} sx={{ gridAutoFlow: ['row', 'row', 'column'] }}>
          {links.map(({ displayName, href, isNewTab }) => (
            <Link
              key={displayName}
              href={href}
              rel="noopener noreferrer"
              target={isNewTab ? '_blank' : '_self'}
              sx={{ variant: 'superCenter', height: pxToRem(48), width: pxToRem(128) }}
            >
              <Text variant="typography.callout.14" color="white" sx={{ textAlign: 'center' }}>
                {displayName}
              </Text>
            </Link>
          ))}
        </Grid>
      )}
      <Text
        variant="typography.caption.small_12"
        color="white"
        sx={{ textAlign: 'center', width: 'full', lineHeight: pxToRem(48) }}
      >
        © KadoKado All Rights Reserved
      </Text>
    </Flex>
  )
}

export default memo(Footer)
