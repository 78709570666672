import { useStateInMounted } from '@/hooks/useStateInMounted'
import { getDisplayName } from '@/utils'
import React, { ComponentType, memo, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface ExtraProps {}

type ExclusiveProps = ''

function withPortal<T extends {}>(Component: ComponentType<T>, selector: string) {
  const NewComponent = (props: Omit<T & ExtraProps, ExclusiveProps>) => {
    const [isMounted, setIsMounted] = useStateInMounted(false)

    useEffect(() => {
      setIsMounted(true)
      return () => setIsMounted(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isMounted
      ? createPortal(<Component {...(props as T)} />, document.querySelector(selector) as Element)
      : null
  }

  NewComponent.displayName = `withPortal[${getDisplayName(Component)}]`

  return memo(NewComponent)
}

export default withPortal
