import type { ThemeConfig } from 'antd'

const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: '#FF8674',
    colorSuccess: '#35BCBC',
    colorError: '#E65442',
  },
  components: {
    Table: {
      borderRadius: 0,
      headerBg: '#EDF2F7',
      algorithm: true, // 啟用算法後，才能夠把設定覆蓋過去 token 的設定
    },
    Popover: {
      colorBgElevated: 'rgba(0, 0, 0, 0.75)',
      colorText: '#FFFFFF',
      borderRadiusLG: 2,
      padding: 2,
      algorithm: true,
    },
  },
}

export default antdTheme
