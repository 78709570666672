import { useStateInMounted } from '@/hooks/useStateInMounted'
import React, { createContext, Key, memo, ReactNode, useCallback, useEffect } from 'react'

interface Props {
  children: ReactNode
}

interface BodyLockedContextValues {
  handleAddTrigger: (trigger: Key) => void
  handleRemoveTrigger: (trigger: Key) => void
}

export const BodyLockedContext = createContext<BodyLockedContextValues>({
  handleAddTrigger: () => {},
  handleRemoveTrigger: () => {},
} as BodyLockedContextValues)

/**
 * @name 監聽觸發body鎖定捲動的對象
 * @description
 * 因為現有的設計，很有可能在畫面同時出現多的Dialog、Dropdown
 * 就會出現一個問題，當多個Dialog出現在頁面時，其中一個Dialog卸載，Body捲動的鎖定就會被那其中一個Dialog解除
 * 所以新的做法是，紀錄誰觸發鎖定Body捲動，只有當沒有任何觸發者時，才解除Body捲動
 */
const BodyLockedProvider = ({ children }: Props) => {
  // 誰觸發鎖定Body捲動
  const [triggers, setTriggers] = useStateInMounted<Key[]>([])

  /**
   * @name 新增觸發Body鎖定捲動的對象
   * @param trigger 觸發Body鎖定捲動的對象（ID）
   */
  const handleAddTrigger = useCallback((trigger: Key) => {
    setTriggers(prevState => [...prevState, trigger])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @name 移除觸發Body鎖定捲動的對象
   * @param trigger 觸發Body鎖定捲動的對象（ID）
   */
  const handleRemoveTrigger = useCallback((trigger: Key) => {
    setTriggers(prevState => prevState.filter(oldTrigger => oldTrigger !== trigger))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('html')[0]

    // 如果沒有任何觸發者，則允許body捲動
    bodyElement.style.overflow = triggers.length > 0 ? 'hidden' : 'auto'
  }, [triggers])

  return (
    <BodyLockedContext.Provider
      value={{
        handleAddTrigger,
        handleRemoveTrigger,
      }}
    >
      {children}
    </BodyLockedContext.Provider>
  )
}

export default memo(BodyLockedProvider)
