import { useGetMeQuery } from '@/api/query'
import { routes } from '@/constants/routes'
import useAnalyticsEvent from '@/hooks/useAnalyticsEvent'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { createContext, Fragment, memo, ReactNode } from 'react'
import { Box, Spinner } from 'theme-ui'

const SignInDialog = dynamic(() => import('@/components/SignInDialog'), { ssr: false })

interface Props {
  children: ReactNode
}

interface IAuthenticationContext {
  isSignedIn: boolean
}

// 不需要登入的頁面清單
const UN_PROTECTED_ROUTES = new Set([routes.TERMS])

export const AuthenticationContext = createContext<IAuthenticationContext>(
  {} as IAuthenticationContext
)

/**
 * @name AuthenticationProvider
 * @description
 * Provider提供：
 * - isSignedIn（是否已登入）
 */
const AuthenticationProvider = ({ children }: Props) => {
  const router = useRouter()
  const { pushEvent } = useAnalyticsEvent()
  const meQuery = useGetMeQuery({
    onSuccess: data => {
      // TODO: 目前不知為何未登入 401 時，data 為 undefined 還是會進到 onSuccess 中，導致錯誤發生，暫時先用 optional chaining 短解
      pushEvent({
        user_id: data?.memberId,
        email: data?.email,
        memberKind: data?.memberKind,
      })
    },
  })
  const isSignedIn = !!meQuery.data

  const renderContent = () => {
    if (isSignedIn || UN_PROTECTED_ROUTES.has(router.pathname)) {
      return <Fragment>{children}</Fragment>
    }

    if (meQuery.isLoading) {
      return (
        <Box variant="dialog" sx={{ variant: 'superCenter', backgroundColor: 'background' }}>
          <Spinner variant="styles.spinner" />
        </Box>
      )
    }

    return <SignInDialog isShow />
  }

  return (
    <AuthenticationContext.Provider value={{ isSignedIn }}>
      {renderContent()}
    </AuthenticationContext.Provider>
  )
}

export default memo(AuthenticationProvider)
