import PrimaryButton from '@/components/PrimaryButton'
import Spacer from '@/components/Spacer'
import DialogContainer from '@/containers/DialogContainer'
import { goToAccount, pxToRem } from '@/utils'
import { useRouter } from 'next/router'
import React, { memo, useCallback } from 'react'
import { Flex, Image, Text } from 'theme-ui'

interface Props {
  isShow: boolean
}

const VerifiedEmailDialog = ({ isShow }: Props) => {
  const router = useRouter()

  const handleVerifyEmail = useCallback(() => {
    goToAccount('/email/verify', router.asPath, 'new')
  }, [router.asPath])

  return (
    <DialogContainer title="你的 Email 尚未驗證" isShow={isShow} isClosable={false}>
      <Flex sx={{ flexFlow: 'column nowrap', alignItems: 'center' }}>
        <Image
          src="/static/images/Kadokado-notice-Email_128.svg"
          alt="login"
          sx={{ width: pxToRem(128), height: pxToRem(128), objectFit: 'contain' }}
        />
        <Spacer height={3} />
        <Text variant="typography.body.14">Email 驗證後才能繼續使用更多服務唷！</Text>
        <Spacer height={4} />
        <PrimaryButton label="立即驗證" onClick={handleVerifyEmail} />
      </Flex>
    </DialogContainer>
  )
}

export default memo(VerifiedEmailDialog)
