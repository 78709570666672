import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { DefaultOptions } from 'react-query'

dayjs.extend(duration)

const second = (time: number) => dayjs.duration(time, 'second').asMilliseconds()
const minute = (time: number) => dayjs.duration(time, 'minute').asMilliseconds()
const hour = (time: number) => dayjs.duration(time, 'hour').asMilliseconds()
const day = (time: number) => dayjs.duration(time, 'day').asMilliseconds()

// React Query 預設
export const defaultQueriesOptions: DefaultOptions['queries'] = {
  refetchOnWindowFocus: false,
  retry: false,
}

export const queryOptions: {
  [key: string]: Partial<{
    // ↓ ↓ ↓ ↓ ↓ ↓ 照這個順序定義 ↓ ↓ ↓ ↓ ↓ ↓
    staleTime: number
    cacheTime: number
    refetchOnWindowFocus: boolean
    retry: number | boolean
  }>
} = {
  useGetTitleByIdQuery: {
    staleTime: day(1),
    cacheTime: hour(1),
  },
  useGetMeQuery: {
    staleTime: day(1),
    cacheTime: hour(1),
  },
  useGetChapterQuery: {
    staleTime: minute(10),
    cacheTime: 0,
    refetchOnWindowFocus: true,
  },
  useGetChapterListQuery: {
    staleTime: hour(1),
    cacheTime: hour(1),
  },
  useGetChapterViewCountEntriesQuery: {
    staleTime: hour(1),
    cacheTime: hour(1),
    refetchOnWindowFocus: true,
  },
  useGetChapterSortingListQuery: {
    staleTime: hour(1),
    cacheTime: 0,
  },
  useGetChapterSettingQuery: {
    staleTime: day(1),
    cacheTime: hour(1),
  },
  useGetChapterListingStatusQuery: {
    staleTime: minute(1),
    cacheTime: 0,
    refetchOnWindowFocus: true,
  },
  useGetGenresQuery: {
    staleTime: day(1),
    cacheTime: Infinity,
  },
  useGetVerifiedAuthorStatusQuery: {
    staleTime: hour(1),
    cacheTime: 0,
  },
  useGetMyStatisticsReportQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetWithdrawalRecordsQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetRevenueReportQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetRevenueDetailQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetMyOverviewQuery: {
    staleTime: minute(10),
    cacheTime: 0,
    refetchOnWindowFocus: true,
  },
  useGetAnnouncementListQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetAnnouncementByIdQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetContestQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetContestableTitleListQuery: {
    staleTime: Infinity,
    cacheTime: 0,
  },
  useContestGroupListQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetMyChapterCommentsQuery: {
    staleTime: minute(2),
    cacheTime: 0,
    refetchOnWindowFocus: true,
  },
  useInfiniteGetCommentsQuery: {
    staleTime: minute(2),
    cacheTime: 0,
    refetchOnWindowFocus: true,
  },
  useCheckMyReportsQuery: {
    staleTime: hour(1),
    cacheTime: minute(10),
  },
  useGetPinnedCommentQuery: {
    staleTime: hour(1),
    cacheTime: 0,
  },
  useGetMyTitleStatsQuery: {
    staleTime: hour(1),
    cacheTime: minute(30),
  },
  useGetChapterReadingTicketSettingsQuery: {
    staleTime: minute(10),
    cacheTime: hour(1),
  },
  useGetWithdrawalInformationQuery: {
    staleTime: hour(1),
    cacheTime: hour(2),
  },
  useGetMonthlyStatsTotalQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetMonthlyStatsByTitleIdQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetTotalStatsTotalOverallsQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useGetTotalStatsTitleOverallsQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
  useInfiniteGetReceivedGiftsQuery: {
    staleTime: day(1),
    cacheTime: hour(1),
  },
  useGetContestsQuery: {
    staleTime: day(1),
    cacheTime: day(1),
  },
}
