/** @jsxImportSource theme-ui */
import { pxToRem } from '@/utils'
import { Fragment, memo, ReactNode } from 'react'
import { Button, ButtonProps, Flex, Spinner } from 'theme-ui'

interface Props {
  label: string
  prefixIcon?: ReactNode
  isLoading?: boolean
}

/**
 * @name PrimaryButton
 * @param props.label 按鈕文字
 * @param props.prefixIcon 前綴icon(24x24)
 * @param props.isLoading loading狀態
 * @description
 * 1) prefixIcon是前綴icon，可選擇性傳入，會有24x24的容器包覆並置中傳入的元件
 * 2) loading狀態的時候，會disable按鈕，並顯示spinner，不傳入isLoading就不會觸發
 * 3) props包含ButtonProps，Theme-UI Button元件可以使用的所有props都可以使用
 * 4) 引用PrimaryButton，可以透過<PrimaryButton sx={{ //customStyle }} />去修改外層樣式
 * 5) 繼續4，想要修改元件內的樣式，也可以定義的className去做修改，ex: .primary-button__icon
 */
const PrimaryButton = ({
  label,
  prefixIcon,
  isLoading = false,
  ...buttonProps
}: Props & ButtonProps) => {
  return (
    <Button
      sx={{ width: 'full', maxWidth: pxToRem(256), borderRadius: 'extra' }}
      {...buttonProps}
      disabled={isLoading || buttonProps.disabled}
    >
      {isLoading ? (
        <Spinner variant="styles.spinner" size={24} />
      ) : (
        <Fragment>
          {prefixIcon && (
            <Flex
              className="primary-button__icon"
              variant="superCenter"
              sx={{ width: pxToRem(24), height: pxToRem(24), overflow: 'hidden' }}
            >
              {prefixIcon}
            </Flex>
          )}
          {label}
        </Fragment>
      )}
    </Button>
  )
}

export default memo(PrimaryButton)
