import { getWithdrawalInformation, getRevenueReport, getWithdrawalRecords } from '@/api/queryFn'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

export function useGetWithdrawalInformationQuery<
  TData = Awaited<ReturnType<typeof getWithdrawalInformation>>,
  TError = AxiosError
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalInformation>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.WITHDRAWAL_INFORMATION],
    queryFn: getWithdrawalInformation,
    ...queryOptions.useGetWithdrawalInformationQuery,
    ...options,
  })
}

export function useGetRevenueReportQuery<
  TData = Awaited<ReturnType<typeof getRevenueReport>>,
  TError = AxiosError
>(
  params: {
    startDate: string
    endDate: string
  },
  options?: UseQueryOptions<Awaited<ReturnType<typeof getRevenueReport>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.REVENUE_REPORT, params],
    queryFn: () => getRevenueReport(params),
    ...queryOptions.useGetRevenueReportQuery,
    ...options,
  })
}

export function useGetWithdrawalRecordsQuery<
  TData = Awaited<ReturnType<typeof getWithdrawalRecords>>,
  TError = AxiosError
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getWithdrawalRecords>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.WITHDRAWAL_RECORDS],
    queryFn: getWithdrawalRecords,
    ...queryOptions.useGetWithdrawalRecordsQuery,
    ...options,
  })
}
