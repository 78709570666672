/** @jsxImportSource theme-ui */
import { memo } from 'react'
import { get, useThemeUI } from 'theme-ui'

interface Props {
  width?: string | number
  height?: string | number
}

/**
 * @name Spacer（獨立處理間距的元件）
 * @param props.width Spacer的寬度
 * @param props.height Spacer的高度
 * @description
 * 1) 寬跟高，不傳值的那一個會預設100%
 * 2) 傳數字，會對應theme裡面的space，ex: width: 1 => theme.space[1]，Index不存在也是預設100%
 */
const Spacer = ({ width, height }: Props) => {
  const { theme } = useThemeUI()

  const w = typeof width === 'number' ? get(theme, 'space')[width] : width
  const h = typeof height === 'number' ? get(theme, 'space')[height] : height

  return <div sx={{ width: w ?? 'full', height: h ?? 'full', flex: '0 0 auto' }} />
}

export default memo(Spacer)
