import endpoints from '@/constants/endpoints'
import axios from 'axios'

// TODO 留言 API 有 v2 後，可以移除這個 API 以及它的應用
/**
 * @name 評論留言時觸發後端發送Amplitude事件
 * @param params.chapterId 章節 ID
 * @param params.commentId 留言 ID
 * @param params.parentCommentId 上層留言 ID
 */
export const trackEventOnly = async (params: {
  chapterId: number
  commentId: number
  parentCommentId?: number
}): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.TRACK_EVENT_ONLY,
    data: params,
  })
  return res.data
}
