import { useEffect, useRef } from 'react'

export const useIsComponentMounted = () => {
  const isMounted = useRef<boolean>(false)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  return isMounted
}
