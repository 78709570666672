enum ErrorCode {
  VALIDATION_ERROR = 'ValidationError',
  NOT_FOUND = 'NotFound',
  THROTTLING_EXCEPTION = 'ThrottlingException',
  INTERNAL_FAILURE = 'InternalFailure',
  SERVICE_UNAVAILABLE = 'ServiceUnavailable',
  UNAUTHORIZED = 'Unauthorized',
}

export interface ErrorV2 {
  errors: string[]
  mainError: {
    code: ErrorCode
    title: string
    message: string
    buttonText: string
  }
  requestId: string
}
