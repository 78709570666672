/** @jsxImportSource theme-ui */
import AppBar from '@/components/AppBar'
import Footer from '@/components/Footer'
import { memo, ReactNode } from 'react'
import { Flex } from 'theme-ui'

interface Props {
  children: ReactNode
}

/**
 * @name 全域佈局
 */
const GlobalLayoutContainer = ({ children }: Props) => {
  return (
    <Flex sx={{ flexFlow: 'column nowrap', position: 'relative', minHeight: '100vh' }}>
      <AppBar />
      <Flex as="main" sx={{ flex: '1 1 0', width: 'full', flexFlow: 'column nowrap' }}>
        {children}
      </Flex>
      <Footer />
    </Flex>
  )
}

export default memo(GlobalLayoutContainer)
