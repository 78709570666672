/** @jsxImportSource theme-ui */
import ToastMessage, { IToastMessage, MessageTypes } from '@/components/ToastMessage'
import { useStateInMounted } from '@/hooks/useStateInMounted'
import { pxToRem } from '@/utils'
import { getUniqueId } from '@/utils/uniqueId'
import { createContext, Key, memo, ReactNode, useCallback } from 'react'
import { animated, useTransition } from 'react-spring'
import { Container, Flex } from 'theme-ui'

interface IMessage {
  (content: String | ReactNode): void
  error: (content: String | ReactNode) => void
  success: (content: String | ReactNode) => void
  info: (content: String | ReactNode) => void
}
interface Props {
  children: ReactNode
}

interface IToastMessageContext {
  message: IMessage
}

export const ToastMessageContext = createContext<IToastMessageContext>({} as IToastMessageContext)

// toast message顯示多久(ms)
const SELF_DESTROY_TIMEOUT = 3000

/**
 * @name ToastMessageProvider（取得message函式）
 * @description
 * 1) 取得message函式，用來觸發顯示Toast訊息
 * 2) message接受一個變數，字串，或是傳入元件來客製化Toast內容
 * 3) Toast樣式
 * message()         => 一般樣式
 * message.success() => 成功樣式
 * message.error()   => 失敗樣式
 * message.info()    => Info樣式
 * @tutorial
 * const { message } = useContext(ToastMessageContext)
 * message.success('成功訊息')
 * 或
 * message.success(<Component />)
 */
const ToastMessageProvider = ({ children }: Props) => {
  const [messageList, setMessageList] = useStateInMounted<(IToastMessage & { id: Key })[]>([])

  const transition = useTransition(messageList, {
    from: { opacity: 0, transform: `translate3d(${pxToRem(0)}, ${pxToRem(20)}, 0)` },
    enter: { opacity: 1, transform: `translate3d(${pxToRem(0)}, ${pxToRem(0)}, 0)` },
    leave: { opacity: 0, transform: `translate3d(${pxToRem(20)}, ${pxToRem(0)}, 0)` },
  })

  const deleteMessage = useCallback((id: Key) => {
    setMessageList(prevState => prevState.filter(message => message.id !== id))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const messageWithType = useCallback(
    (type?: MessageTypes) => (content: String | ReactNode) => {
      const id = getUniqueId()

      // 1) 新增message
      setMessageList(prevState => [...prevState, { id, type, content }])
      //2) 設定自動移除
      setTimeout(() => deleteMessage(id), SELF_DESTROY_TIMEOUT)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteMessage]
  )

  const message = Object.assign(
    messageWithType(),
    { error: messageWithType(MessageTypes.ERROR) },
    { info: messageWithType(MessageTypes.INFO) },
    { success: messageWithType(MessageTypes.SUCCESS) }
  )

  return (
    <ToastMessageContext.Provider value={{ message }}>
      {children}
      <Container
        sx={{
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          pointerEvents: messageList.length > 0 ? 'auto' : 'none',
          zIndex: 'toast',
        }}
      >
        <Flex p={4} sx={{ flexFlow: 'column-reverse nowrap', width: 'full', gap: 3 }}>
          {transition(
            (animation, message) =>
              message && (
                <animated.div key={message.id} style={animation}>
                  <ToastMessage type={message.type} content={message.content} />
                </animated.div>
              )
          )}
        </Flex>
      </Container>
    </ToastMessageContext.Provider>
  )
}

export default memo(ToastMessageProvider)
