import { ChapterReadingTicketSettings } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得讀角券設定的作品章節列表
 * @param titleId
 */
export const getChapterReadingTicketSettings = async (
  titleId: number
): Promise<ChapterReadingTicketSettings> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.CHAPTER_READING_TICKET_SETTINGS,
    params: { titleId },
  })
  return res.data
}

/**
 * @name 更新讀角券設定的作品章節列表
 * @param data
 */
export const updateChapterReadingTicketSettings = async (
  data: { chapterId: number; allowReadingTicket: boolean }[]
) => {
  const res = await axios({
    method: 'PUT',
    url: endpoints.CHAPTER_READING_TICKET_SETTINGS,
    data,
  })
  return res.data
}
