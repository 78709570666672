import { IS_CLIENT_SIDE } from '@/utils'
import { useCallback } from 'react'

/**
 * @name useAnalyticsEvent
 * @description
 * 透過 window.dataLayer.push 來觸發 Google Analytics 事件
 * @returns
 * pushEvent: 傳送事件
 */

const useAnalyticsEvent = () => {
  const pushEvent = useCallback((data: { [key: string]: any }) => {
    if (!IS_CLIENT_SIDE) return

    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push(data)
  }, [])

  return { pushEvent }
}

export default useAnalyticsEvent
