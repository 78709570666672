import {
  getMonthlyStatsByTitleId,
  getMonthlyStatsTotal,
  getTotalStatsTitleOveralls,
  getTotalStatsTotalOveralls,
} from '@/api/queryFn'
import { DateRange, ErrorV2, Title } from '@/api/types'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

export function useGetMonthlyStatsTotalQuery<
  TData = Awaited<ReturnType<typeof getMonthlyStatsTotal>>,
  TError = AxiosError<ErrorV2>
>(
  { startDate, endDate }: DateRange,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getMonthlyStatsTotal>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.MONTHLY_STATS_TOTAL, { startDate, endDate }],
    queryFn: () => getMonthlyStatsTotal({ startDate, endDate }),
    ...queryOptions.useGetMonthlyStatsTotalQuery,
    ...options,
  })
}

export function useGetMonthlyStatsByTitleIdQuery<
  TData = Awaited<ReturnType<typeof getMonthlyStatsByTitleId>>,
  TError = AxiosError<ErrorV2>
>(
  { titleId, startDate, endDate }: Pick<Title, 'titleId'> & DateRange,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getMonthlyStatsByTitleId>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.MONTHLY_STATS_TITLES, { titleId, startDate, endDate }],
    queryFn: () => getMonthlyStatsByTitleId({ titleId, startDate, endDate }),
    ...queryOptions.useGetMonthlyStatsByTitleIdQuery,
    ...options,
  })
}

export function useGetTotalStatsTotalOverallsQuery<
  TData = Awaited<ReturnType<typeof getTotalStatsTotalOveralls>>,
  TError = AxiosError<ErrorV2>
>(
  options?: UseQueryOptions<Awaited<ReturnType<typeof getTotalStatsTotalOveralls>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.TOTAL_STATS_TOTAL_OVERALLS],
    queryFn: () => getTotalStatsTotalOveralls(),
    ...queryOptions.useGetTotalStatsTotalOverallsQuery,
    ...options,
  })
}

export function useGetTotalStatsTitleOverallsQuery<
  TData = Awaited<ReturnType<typeof getTotalStatsTitleOveralls>>,
  TError = AxiosError<ErrorV2>
>(
  { titleId }: Pick<Title, 'titleId'>,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getTotalStatsTitleOveralls>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.TOTAL_STATS_TITLE_OVERALLS, { titleId }],
    queryFn: () => getTotalStatsTitleOveralls({ titleId }),
    ...queryOptions.useGetTotalStatsTitleOverallsQuery,
    ...options,
  })
}
