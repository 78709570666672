import { DateRange, DailyStatisticsReport } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const getMyStatisticsReport = async ({
  startDate,
  endDate,
}: DateRange): Promise<DailyStatisticsReport[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.MY_STATISTICS_REPORT,
    params: { startDate, endDate },
  })
  return res.data
}
