/** @jsxImportSource theme-ui */
import { useGetMeQuery } from '@/api/query'
import { MemberKind } from '@/api/types'
import { memo } from 'react'
import { AvatarProps } from 'theme-ui'

import Avatar, { Size } from '.'

interface Props {
  size: Size
}

const DEFAULT_MEMBER_KIND = MemberKind.Regular

/**
 * @name MyAvatar
 * @param props.size avatar 尺寸
 * @description
 * 1) size : small-40、large-80、xLarge-128、xxLarge-256
 */
const MyAvatar = ({ size, ...avatarProps }: Props & AvatarProps) => {
  const meQuery = useGetMeQuery()

  return (
    <Avatar
      size={size}
      memberKind={meQuery.data?.memberKind ?? DEFAULT_MEMBER_KIND}
      avatarUrl={meQuery.data?.avatar}
      isLoading={meQuery.isLoading}
      {...avatarProps}
    />
  )
}

export default memo(MyAvatar)
