import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const signOut = async (): Promise<{}> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.SIGN_OUT,
  })
  return res.data
}
