import { Me, VerificationStatus } from '@/api/types/members'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const getMe = async (): Promise<Me> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.ME,
  })
  return res.data
}

/**
 * @name 取得認證作家申請狀態
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Members/get_v2_members_me_verifiedAuthor
 */
export const getVerifiedAuthorStatus = async (): Promise<{
  verificationStatus: VerificationStatus | null
  verifiedAuthorId: number
}> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.VERIFIED_AUTHOR,
  })
  return res.data
}

/**
 * @name 刪除認證作家申請
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Members/delete_v2_members_me_verifiedAuthor
 */
export const deleteVerifiedAuthor = async (): Promise<{ deletedVerifiedAuthorId: number }> => {
  const res = await axios({
    method: 'DELETE',
    url: endpoints.VERIFIED_AUTHOR,
  })
  return res.data
}
