/** @jsxImportSource theme-ui */
import { useGetMeQuery } from '@/api/query'
import { LatestStatus } from '@/api/types'
import VerifiedEmailDialog from '@/components/VerifiedEmailDialog'
import { routes } from '@/constants/routes'
import { useRouter } from 'next/router'
import { Fragment, memo, ReactNode } from 'react'
import { Box, Spinner } from 'theme-ui'

interface Props {
  children: ReactNode
}

// 不需要登入的頁面清單
const UN_PROTECTED_ROUTES = new Set([routes.TERMS])

/**
 * @name 檢查Email是否認證
 * @description
 * Email認證過的使用者，才能進入創作者中心
 */
const VerifiedEmailCheckContainer = ({ children }: Props) => {
  const router = useRouter()
  const meQuery = useGetMeQuery()
  const myLatestStatus = meQuery.data?.latestStatus

  if (myLatestStatus === LatestStatus.VALID || UN_PROTECTED_ROUTES.has(router.pathname)) {
    return <Fragment>{children}</Fragment>
  }

  if (myLatestStatus === LatestStatus.REGISTERED) {
    return <VerifiedEmailDialog isShow />
  }

  return <Spinner variant="styles.spinner" sx={{ variant: 'fixedCenter' }} />
}

export default memo(VerifiedEmailCheckContainer)
