/**
 * @name 將數字轉乘K/M/B
 * @param number
 * @returns 轉出後的字串
 * @description
 * 依照設計稿定義
 * thousand 仟  縮寫為：大寫的『Ｋ』
 * million 百萬  縮寫為：大寫的『Ｍ』
 * billion 十億  縮寫為：大寫的『B』
 * trillion 兆  縮寫為：大寫的『T』
 * @example
 * convertNumberToKmb(1000) => 1K
 * convertNumberToKmb(1200) => 1.2K
 */
export const convertNumberToKmb = (number: number | undefined): string => {
  if (number == undefined) {
    return ''
  }

  // 兆
  if (number >= 1e12) {
    const trillions = number / 1e12
    return trillions.toFixed(1) + ' Ｔ'
  }

  // 十億
  if (number >= 1e9) {
    const billions = number / 1e9
    return billions.toFixed(1) + ' Ｂ'
  }

  // 百萬
  if (number >= 1e6) {
    const millions = number / 1e6
    return millions.toFixed(1) + ' Ｍ'
  }

  // 千
  if (number >= 1000) {
    const thousands = number / 1000
    return thousands.toFixed(1) + ' Ｋ'
  }

  return number.toString()
}

/**
 * @name removeUnnecessarySpaces
 * @description
 * 去除字串前後的空白，並將兩個以上的空格統一為一個空格
 */
export const removeUnnecessarySpaces = (text: string) => {
  return text.trim().replace(/\s\s+/g, ' ')
}

/**
 * @name thousandSeparator
 * @description
 * 數字加入千分位
 * @example
 * thousandSeparator(1000) === "1,000"
 * thousandSeparator(1000123.456789) === "1,000,123.456789"
 */
export const thousandSeparator = (number: number | undefined | string): string => {
  if (number == undefined) return ''

  const numberString = typeof number === 'number' ? number.toString() : number
  const numberParts = numberString.split('.')

  // 正整數
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return numberParts.join('.')
}
