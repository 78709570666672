import endpoints from '@/constants/endpoints'
import axios from 'axios'

export const chapterSavedTrackEvent = async (chapterId: number) => {
  const res = await axios({
    method: 'POST',
    url: endpoints.CHAPTER_SAVED_TRACK_EVENT,
    data: { chapterId },
  })
  return res.data
}

export const chapterCreatedTrackEvent = async (chapterId: number) => {
  const res = await axios({
    method: 'POST',
    url: endpoints.CHAPTER_CREATED_TRACK_EVENT,
    data: { chapterId },
  })
  return res.data
}

export const chapterPublishedTrackEvent = async (chapterId: number) => {
  const res = await axios({
    method: 'POST',
    url: endpoints.CHAPTER_PUBLISHED_TRACK_EVENT,
    data: { chapterId },
  })
  return res.data
}

export const titlePublishedTrackEvent = async (titleId: number) => {
  const res = await axios({
    method: 'POST',
    url: endpoints.TITLE_PUBLISHED_TRACK_EVENT,
    data: { titleId },
  })
  return res.data
}
