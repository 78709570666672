import { useIsComponentMounted } from '@/hooks/useIsComponentMounted'
import { Dispatch, SetStateAction, useState } from 'react'

export const useStateInMounted = <T>(initialState: T): [T, Dispatch<SetStateAction<T>>] => {
  const isMounted = useIsComponentMounted()
  const [state, setState] = useState<T>(initialState)

  const setStateInMounted = (newState: T | ((prev: T) => T)) => {
    if (isMounted.current) setState(newState)
  }

  return [state, setStateInMounted]
}
