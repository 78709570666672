/** @jsxImportSource theme-ui */
import MyAvatar from '@/components/Avatar/MyAvatar'
import { routes } from '@/constants/routes'
import { HeaderVisibilityContext } from '@/containers/HeaderVisibilityProvider'
import { useStateInMounted } from '@/hooks/useStateInMounted'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { memo, useCallback, useContext, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { Box, Image } from 'theme-ui'

const SideMenu = dynamic(() => import('@/components/SideMenu'), { ssr: false })

const LOGO = '/static/images/KadoKado-creator-Logo_64.svg'

const AnimatedBox = animated(Box)

const AppBar = () => {
  const router = useRouter()
  const { isHeaderShow } = useContext(HeaderVisibilityContext)
  const { transform } = useSpring({
    transform: `translate3d(${isHeaderShow ? '0, 0%, 0' : '0, -100%, 0'})`,
  })
  // 是否顯示側選單
  const [isSideMenuShow, setIsSideMenuShow] = useStateInMounted(true)

  const handelOpenSideMenu = useCallback(() => {
    setIsSideMenuShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handelCloseSideMenu = useCallback(() => {
    setIsSideMenuShow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // url 改變時，關閉側選單
  useEffect(() => {
    if (isSideMenuShow) handelCloseSideMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  return (
    <AnimatedBox as="header" variant="appBar" style={{ transform }}>
      <Link
        href={routes.HOME}
        sx={{ height: 'full' }}
        aria-label="kadoKado creator logo"
        rel="noopener"
      >
        <Image src={LOGO} alt="logo" sx={{ height: 'full', width: 'auto' }} />
      </Link>

      <Box as="button" onClick={handelOpenSideMenu}>
        <MyAvatar size="small" />
      </Box>

      <SideMenu isShow={isSideMenuShow} handelCloseSideMenu={handelCloseSideMenu} />
    </AnimatedBox>
  )
}

export default memo(AppBar)
