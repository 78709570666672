import {
  PaginationParams,
  PaginationResponse,
  ReceivedGift,
  ReceivedGiftCondition,
} from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得送禮通知列表
 * @param props.current 當前頁數
 * @param props.limit 每頁筆數
 * @param props.orderByCreatedAt 根據建立時間排序，ASC: 遞增(由舊到新), DESC: 遞減(由新到舊)
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_receivedGifts
 */
export const getReceivedGifts = async ({
  current,
  limit,
  orderByCreatedAt,
}: ReceivedGiftCondition & PaginationParams): Promise<PaginationResponse<ReceivedGift>> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.RECEIVED_GIFTS,
    params: { current, limit, orderByCreatedAt },
  })
  return res.data
}
