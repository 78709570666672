import { MemberKind } from '@/api/types'

export const MEMBER_KIND_CONFIG = {
  [MemberKind.Reader]: {
    label: '一般讀者',
    color: undefined,
  },
  [MemberKind.Regular]: {
    label: '創作作家',
    color: 'gray.5',
  },
  [MemberKind.Verified]: {
    label: '認證作家',
    color: 'success',
  },
  [MemberKind.Contracted]: {
    label: '簽約作家',
    color: 'primary',
  },
}
