/** @jsxImportSource theme-ui */
import { pxToRem } from '@/utils'
import { memo } from 'react'
import { FiCheck, FiCircle } from 'react-icons/fi'
import { animated, useTransition } from 'react-spring'
import { Box, BoxOwnProps, BoxProps, Flex, Text } from 'theme-ui'

interface Props {
  isSelected: boolean
  isDisabled?: boolean
}

const AnimatedFiCheck = animated(FiCheck)

const Checkbox = ({
  isSelected,
  isDisabled = false,
  ...otherProps
}: Props & BoxOwnProps & BoxProps) => {
  const transition = useTransition(isSelected, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <Box
      variant="superCenter"
      sx={{ position: 'relative', width: pxToRem(56), height: pxToRem(56) }}
      {...otherProps}
    >
      <Box
        variant="superCenter"
        sx={{
          backgroundColor:
            isSelected && isDisabled ? 'gray.4' : isSelected ? 'primary' : 'transparent',
          width: pxToRem(20),
          height: pxToRem(20),
          borderRadius: pxToRem(2),
          borderColor: isDisabled ? 'gray.4' : 'primary',
          borderWidth: pxToRem(2),
          borderStyle: 'solid',
        }}
      >
        {transition(
          ({ opacity }, isSelected) =>
            isSelected && (
              <AnimatedFiCheck sx={{ color: 'gray.0', fontSize: 4 }} style={{ opacity }} />
            )
        )}
      </Box>
    </Box>
  )
}

export default memo(Checkbox)
