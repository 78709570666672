import { ampli, ApiKey } from '@/ampli'
import { IS_CLIENT_SIDE } from '@/utils'
import { useEffect } from 'react'

const getApiKey = (): string | undefined => {
  // @ts-ignore
  if ('kadokado_prod' in ApiKey) return ApiKey.kadokado_prod
  // @ts-ignore
  if ('default' in ApiKey) return ApiKey.default
}

/**
 * @name 初始化AmpliWrapper
 */
const useAmplitudeInitialize = () => {
  const apiKey = getApiKey()

  useEffect(() => {
    if (IS_CLIENT_SIDE && !!apiKey) {
      ampli.load({
        client: {
          apiKey, // 對應的 Key 會指向對應的環境
          configuration: {
            defaultTracking: true, // 解決錯誤： `options.defaultTracking` is set to undefined
            minIdLength: 1, // 解決錯誤： Invalid id length for user_id or device_id.
            serverUrl:
              process.env.NEXT_PUBLIC_ENV !== 'DEV'
                ? `${window.location.origin}/amplitude` // 除了 DEV 環境，設定 serverUrl 到 /amplitude 路徑
                : undefined,
          },
        },
      })
    }
  }, [apiKey])
}

export default useAmplitudeInitialize
