import { IS_CLIENT_SIDE, IS_PROD_EXEC_ENV } from '@/utils'
import axios from 'axios'

axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL
axios.defaults.headers.accept = 'application/json'
axios.defaults.withCredentials = true

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    // Do something before request is sent
    try {
      // 因應大陸市場需求，動態產生api base url
      if (
        IS_CLIENT_SIDE &&
        IS_PROD_EXEC_ENV &&
        window.location.origin !== process.env.NEXT_PUBLIC_BASE_URL
      ) {
        const { origin } = window.location

        config.baseURL = origin.replace('creator', 'api')
      }
    } catch (error) {}
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)
