import dayjs, { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * @name dayjsTimeZoneTW
 * @description
 * 轉為台灣時區時間
 */
export const dayjsTimeZoneTW = (time: string | number | Date | Dayjs | null | undefined): Dayjs =>
  dayjs(time).tz('Asia/Taipei')

/**
 * @name getTodayPassedHours
 * @description
 * 取得今日已過的小時列表
 */
const getTodayPassedHours = () => Array.from({ length: dayjs().hour() + 1 }, (_, index) => index)

/**
 * @name getDisabledHours
 * @description
 * 取得需要 disabled 的小時
 */
export const getDisabledHours = (currentDate: Dayjs | null) => {
  // 第一次打開 date picker 時，currentDate 值會是 null，故預設為今天
  const date = currentDate || dayjs()

  // 若 date 為今天的話，要 disabled 今天已過的 hours
  const disabledHours = dayjs().isSame(date, 'date') ? getTodayPassedHours : undefined

  return { disabledHours }
}
