import DialogContainer from '@/containers/DialogContainer'
import { useStateInMounted } from '@/hooks/useStateInMounted'
import React, { createContext, memo, ReactNode, useCallback } from 'react'

interface IDialog {
  title: string
  content: ReactNode
}

interface Props {
  children: ReactNode
}

interface IDialogContext {
  openDialog: (newDialog: IDialog) => void
  closeDialog: () => void
}

export const DialogContext = createContext<IDialogContext>({
  openDialog: () => {},
  closeDialog: () => {},
})

const DialogProvider = ({ children }: Props) => {
  const [isShow, setIsShow] = useStateInMounted(false)
  const [dialog, setDialog] = useStateInMounted<IDialog | undefined>(undefined)

  const openDialog = useCallback((newDialog: IDialog) => {
    setDialog(newDialog)
    setIsShow(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeDialog = useCallback(() => {
    setIsShow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnRest = useCallback(() => {
    if (!isShow) setDialog(undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow])

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <DialogContainer
        isShow={isShow}
        handleClose={closeDialog}
        title={`${dialog?.title}`}
        handleOnRest={handleOnRest}
      >
        {dialog?.content}
      </DialogContainer>
    </DialogContext.Provider>
  )
}

export default memo(DialogProvider)
