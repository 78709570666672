import { Contest } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得可參賽列表
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_contests
 */
export const getContests = async (): Promise<Contest[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.CONTESTS,
  })
  return res.data
}
