export enum ReceivedGiftOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface ReceivedGift {
  giftDeliveryId: number
  createdAt: string
  giftDisplayName: string
  giftImgUrl: string
  giverAvatarUrl: string | null
  giverDisplayName: string | null
  message: string | null
}

export interface ReceivedGiftCondition {
  orderByCreatedAt: ReceivedGiftOrder
}
