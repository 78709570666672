/** @jsxImportSource theme-ui */
import { memo, ReactNode } from 'react'
import { FiAlertCircle, FiCheckCircle, FiInfo } from 'react-icons/fi'
import { Message } from 'theme-ui'

export enum MessageTypes {
  SUCCESS,
  ERROR,
  INFO,
}

export interface IToastMessage {
  type?: MessageTypes
  content: ReactNode | String
}

const ToastMessage = ({ type, content }: IToastMessage) => {
  if (type === MessageTypes.INFO) {
    return (
      <Message variant="info">
        <FiInfo sx={{ color: 'primary', fontSize: 4 }} />
        {content}
      </Message>
    )
  }

  if (type === MessageTypes.ERROR) {
    return (
      <Message variant="error">
        <FiAlertCircle sx={{ color: 'error', fontSize: 4 }} />
        {content}
      </Message>
    )
  }

  if (type === MessageTypes.SUCCESS) {
    return (
      <Message variant="success">
        <FiCheckCircle sx={{ color: 'success', fontSize: 4 }} />
        {content}
      </Message>
    )
  }

  return <Message variant="basic">{content}</Message>
}

export default memo(ToastMessage)
