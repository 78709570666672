/** @jsxImportSource theme-ui */
import { agreeCreatorTerms } from '@/api/queryFn/creatorTerms'
import Checkbox from '@/components/Checkbox'
import PrimaryButton from '@/components/PrimaryButton'
import queryKeys from '@/constants/queryKeys'
import { routes } from '@/constants/routes'
import DialogContainer from '@/containers/DialogContainer'
import { ToastMessageContext } from '@/containers/ToastMessageProvider'
import { useStateInMounted } from '@/hooks/useStateInMounted'
import { pxToRem } from '@/utils'
import Link from 'next/link'
import { memo, useCallback, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Flex, IconButton, Image, Text } from 'theme-ui'

interface Props {
  isShow: boolean
}

/**
 * @name 創作者同意條款彈出視窗
 * @param props.isShow 彈出視窗是否開啟
 */
const AgreementDialog = ({ isShow }: Props) => {
  const queryClient = useQueryClient()
  const { message } = useContext(ToastMessageContext)

  const [isAgreed, setIsAgreed] = useStateInMounted(true)

  const { mutate } = useMutation({
    mutationFn: agreeCreatorTerms,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.ME])
      setIsAgreed(true)
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleToggleAgreed = useCallback(() => setIsAgreed(!isAgreed), [isAgreed])

  const handleAgree = useCallback(() => {
    if (!isAgreed) return message.error('請同意「創作者同意條款」')

    mutate()
  }, [isAgreed, message, mutate])

  return (
    <DialogContainer title="歡迎加入 KadoKado 角角者" isShow={isShow} isClosable={false}>
      <Flex sx={{ flexFlow: 'column nowrap', alignItems: 'center' }}>
        <Image
          src="/static/images/Kadokado-pic-introstars_256.svg"
          alt="welcome to kadokado creator"
          sx={{ width: pxToRem(256), height: pxToRem(256), objectFit: 'contain' }}
        />
        <Flex my={3} sx={{ alignItems: 'center', height: pxToRem(48) }}>
          <IconButton onClick={handleToggleAgreed}>
            <Checkbox isSelected={isAgreed} />
          </IconButton>
          <Text variant="typography.body.14" sx={{ a: { color: 'primary', mx: 2 } }}>
            確認同意
            <Link href={routes.TERMS} target="_blank" rel="noopener">
              創作者同意條款
            </Link>
          </Text>
        </Flex>
        <PrimaryButton label="下一步" onClick={handleAgree} />
      </Flex>
    </DialogContainer>
  )
}

export default memo(AgreementDialog)
