import { getMyStatisticsReport } from '@/api/queryFn'
import { DateRange, ErrorV2 } from '@/api/types'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

export function useGetMyStatisticsReportQuery<
  TData = Awaited<ReturnType<typeof getMyStatisticsReport>>,
  TError = AxiosError<ErrorV2>
>(
  { startDate, endDate }: DateRange,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getMyStatisticsReport>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.MY_STATISTICS_REPORT, { startDate, endDate }],
    queryFn: () => getMyStatisticsReport({ startDate, endDate }),
    ...queryOptions.useGetMyStatisticsReportQuery,
    ...options,
  })
}
