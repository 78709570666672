export enum LatestStatus {
  REGISTERED = 'REGISTERED',
  VALID = 'VALID',
  INVALID = 'INVALID',
  DELETED = 'DELETED',
}

// 創作者身份
export enum MemberKind {
  Contracted = 'CONTRACTED', // 簽約作家
  Reader = 'READER', // 單純讀者
  Regular = 'REGULAR', // 一般創作者（一般會員）
  Verified = 'VERIFIED', // 認證作家
}
// 認證作家申請狀態
export enum VerificationStatus {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
}

export interface Me {
  agreedTerms: { creator: boolean }
  avatar: string
  displayName: string
  email: string
  hasAgreedPrivacyPolicy: boolean
  introduction: string
  ktwId: string
  lastCommentReadAt: number
  lastCommentSummaryCreatedAt: number
  lastNotificationReadAt: number
  lastUpdatedTitleReadAt: number
  latestStatus: LatestStatus
  memberId: number
  memberKind: MemberKind
  notifiedBy: {
    LINE: boolean
    email: boolean
    inbox: boolean
  }
  privilege: {
    role: {}
  }
}
