import { TargetAudienceTypes } from '@/api/types/targetAudience'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 更新參賽作品讀者群
 * @param params.titleIds 作品ID
 * @param params.TargetAudience 期望的讀者群
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator%20Titles/put_creator_targetAudience
 */
export const updateTargetAudience = async (params: {
  titleIds: number[]
  targetAudience: TargetAudienceTypes
}) => {
  const res = await axios({
    method: 'PUT',
    url: endpoints.TARGET_AUDIENCE,
    data: params,
  })
  return res.data
}
