/** @jsxImportSource theme-ui */
import { MemberKind } from '@/api/types'
import { MEMBER_KIND_CONFIG } from '@/constants/memberKind'
import { getStaticImage, getStorageImage, pxToRem } from '@/utils'
import Link from 'next/link'
import { Fragment, memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Avatar as TAvatar, AvatarProps } from 'theme-ui'

export type Size = 'small' | 'large' | 'xLarge' | 'xxLarge'

interface Props {
  avatarUrl?: string | null
  size?: Size
  linkHref?: string
  avatarTarget?: '_blank' | '_self'
  isLoading?: boolean
  alt?: string
  memberKind?: MemberKind
}

// 大頭照尺寸
const AVATAR_SIZES = {
  small: pxToRem(40),
  large: pxToRem(80),
  xLarge: pxToRem(128),
  xxLarge: pxToRem(256),
}

// 預設大頭照
const DEFAULT_AVATAR = '/static/images/Kadokado-member_40.svg'

/**
 * @name 頭像元件
 * @param props.avatarUrl 頭像網址
 * @param props.size
 * @param props.linkHref 如果有傳入連結，元件就會包覆在Link物件中被渲染
 * @param props.avatarTarget avatar link target，與 linkHref 搭配使用
 * @param props.isLoading 是否在 loading 狀態
 * @param props.alt 圖片的 alt
 * @param props.memberKind 認證作家身份
 */
const Avatar = ({
  avatarUrl,
  size = 'small',
  linkHref,
  isLoading = false,
  alt = '大頭照',
  memberKind = MemberKind.Reader,
  avatarTarget = '_self',
  sx,
  ...avatarProps
}: Props & AvatarProps) => {
  const avatarStyle = {
    borderStyle: 'solid',
    borderWidth: size === 'small' ? pxToRem(1) : pxToRem(2),
    borderColor: MEMBER_KIND_CONFIG[memberKind].color,
    backgroundColor: MEMBER_KIND_CONFIG[memberKind].color,
  }

  const getAvatarSrc = () => {
    if (!avatarUrl) return DEFAULT_AVATAR
    // 放在專案本身的 /static/images 資料夾
    if (avatarUrl.includes('/static/images') || avatarUrl.startsWith('https')) return avatarUrl

    // 如果 avatarUrl 路徑含有 memberId，即使用者後續自行上傳的，會與預設頭貼使用不同 base url
    return avatarUrl.includes('memberId') ? getStorageImage(avatarUrl) : getStaticImage(avatarUrl)
  }

  const renderContent = () => {
    return (
      <TAvatar
        src={getAvatarSrc()}
        sx={{
          flex: '0 0 auto',
          objectFit: 'cover',
          width: AVATAR_SIZES[size],
          height: AVATAR_SIZES[size],
          borderRadius: 'round',
          overflow: 'hidden',
          display: 'grid',
          ...(memberKind !== MemberKind.Reader ? avatarStyle : {}),
          ...sx,
        }}
        alt={alt}
        {...avatarProps}
      />
    )
  }

  if (isLoading) return <Skeleton circle width={AVATAR_SIZES[size]} height={AVATAR_SIZES[size]} />

  if (!!linkHref) {
    return (
      <Link href={linkHref} target={avatarTarget} rel="noopener noreferrer">
        {renderContent()}
      </Link>
    )
  }

  return <Fragment>{renderContent()}</Fragment>
}

export default memo(Avatar)
