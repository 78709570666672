export enum ViewTypes {
  ALL = 'ALL',
  SINGLE = 'SINGLE',
}

export type MonthlyFavoriteData = Pick<MonthlyStatsData, 'date' | 'favoriteCount' | 'favoriteDelta'>
export type MonthlyReadData = Pick<MonthlyStatsData, 'date' | 'readCount' | 'readDelta'>

export interface MonthlyStatsData {
  date: string
  favoriteCount: number
  favoriteDelta: number
  readCount: number
  readDelta: number
}

export interface MonthlyStats {
  monthlyFavoriteCount: number
  monthlyReadCount: number
  data: MonthlyStatsData[]
}

export interface TotalStats {
  totalFavoriteCount: number
  totalReadCount: number
  totalUnlockCount: number
}
