import { BodyLockedContext } from '@/containers/BodyLockedProvider'
import { getUniqueId } from '@/utils'
import { useContext, useEffect, useRef } from 'react'

/**
 * @name 針對Body進行捲動鎖定
 * @param isLocked 是否鎖定捲動行為
 * @description
 * 會搭配BodyLockedProvider一起使用，因爲會有多的Dialog、Dropdown...等等需要鎖Body捲動的元素同時出現的情境
 * 所以用Provider去紀錄需要鎖Body捲動的元素有哪些，當都沒有人時，才真的解除Body捲動
 */

const useLockBodyScroll = (isLocked: boolean) => {
  // 產生隨機ID
  const trigger = useRef(getUniqueId())
  // 取得新增/移除Trigger的函式
  const { handleAddTrigger, handleRemoveTrigger } = useContext(BodyLockedContext)

  useEffect(() => {
    // 將ID存進變數裡，不然return那邊會報錯
    const currentTrigger = trigger.current
    // 根據isLocked，新增/移除Trigger
    isLocked ? handleAddTrigger(currentTrigger) : handleRemoveTrigger(currentTrigger)

    return () => {
      /**
       * 有時候會出現 isShow && <Component /> 的情況
       * 這時會沒有執行到handleRemoveTrigger
       * 所以在return時也執行handleRemoveTrigger，確保沒有問題
       */
      handleRemoveTrigger(currentTrigger)
    }
  }, [handleAddTrigger, handleRemoveTrigger, isLocked])
}

export default useLockBodyScroll
