import { useStateInMounted } from '@/hooks/useStateInMounted'
import React, { createContext, memo, ReactNode, useEffect, useRef } from 'react'

interface Props {
  children: ReactNode
}
export interface IHeaderVisibilityContext {
  isHeaderShow: boolean
}

export const HeaderVisibilityContext = createContext<IHeaderVisibilityContext>({
  isHeaderShow: true,
})

const STARTING_POINT = 400 // 捲動多少(px)之後開始作用

/**
 * @name 取得Header顯示/隱藏狀態
 * @description
 * Header的顯示與window.addEventListener('scroll', callback)有關，要注意CSS排版不要破壞這個關係
 */
const HeaderVisibilityProvider = ({ children }: Props) => {
  const [isHeaderShow, setIsHeaderShow] = useStateInMounted(true)
  const prevScrollY = useRef(-1)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isScrollUpward = currentScrollY < prevScrollY.current

      // STARTING_POINT前，保持Header顯示；超過STARTING_POINT後，配合頁面捲動往上顯示，往下隱藏
      currentScrollY < STARTING_POINT ? setIsHeaderShow(true) : setIsHeaderShow(isScrollUpward)
      // 將currentScrollY紀錄，以便下一次做比較
      prevScrollY.current = currentScrollY
    }

    window.addEventListener('scroll', handleScroll, { passive: false })
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderVisibilityContext.Provider value={{ isHeaderShow }}>
      {children}
    </HeaderVisibilityContext.Provider>
  )
}

export default memo(HeaderVisibilityProvider)
