import { getMyTitleStats, getTitleById } from '@/api/queryFn'
import { ErrorV2 } from '@/api/types/errors'
import queryKeys from '@/constants/queryKeys'
import { queryOptions } from '@/utils/queryOptions'
import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

export function useGetMyTitleStatsQuery<
  TData = Awaited<ReturnType<typeof getMyTitleStats>>,
  TError = AxiosError<ErrorV2>
>(options?: UseQueryOptions<Awaited<ReturnType<typeof getMyTitleStats>>, TError, TData>) {
  return useQuery({
    queryKey: [queryKeys.MY_TITLE_STATS],
    queryFn: getMyTitleStats,
    ...queryOptions.useGetMyTitleStatsQuery,
    ...options,
  })
}

export function useGetTitleByIdQuery<
  TData = Awaited<ReturnType<typeof getTitleById>>,
  TError = AxiosError<ErrorV2>
>(
  titleId: number,
  options?: UseQueryOptions<Awaited<ReturnType<typeof getTitleById>>, TError, TData>
) {
  return useQuery({
    queryKey: [queryKeys.TITLE, { titleId }],
    queryFn: () => getTitleById(titleId),
    ...queryOptions.useGetTitleByIdQuery,
    ...options,
  })
}
