/** @jsxImportSource theme-ui */
import { useGetMeQuery } from '@/api/query'
import AgreementDialog from '@/components/AgreementDialog'
import { routes } from '@/constants/routes'
import { useRouter } from 'next/router'
import { Fragment, memo, ReactNode } from 'react'
import { Box, Spinner } from 'theme-ui'

interface Props {
  children: ReactNode
}

// 不需要登入的頁面清單
const UN_PROTECTED_ROUTES = new Set([routes.TERMS])

/**
 * @name 檢查是否同意創作者同意條款
 * @description
 * 第一次登入的使用者，需同意創作者同意條款
 */
const AgreementCheckContainer = ({ children }: Props) => {
  const router = useRouter()
  const meQuery = useGetMeQuery()

  if (meQuery.data?.agreedTerms.creator || UN_PROTECTED_ROUTES.has(router.pathname)) {
    return <Fragment>{children}</Fragment>
  }

  if (!meQuery.data?.agreedTerms.creator) {
    return <AgreementDialog isShow />
  }

  return (
    <Box variant="dialog" sx={{ variant: 'superCenter', backgroundColor: 'background' }}>
      <Spinner variant="styles.spinner" />
    </Box>
  )
}

export default memo(AgreementCheckContainer)
