import { DateRange, MonthlyStats, Title, TotalStats } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 取得該月所有作品的閱覽累積數、閱覽變化量、收藏累積數、收藏變化量
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_monthlyStats_total
 */
export const getMonthlyStatsTotal = async ({
  startDate,
  endDate,
}: DateRange): Promise<MonthlyStats> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.MONTHLY_STATS_TOTAL,
    params: { startDate, endDate },
  })
  return res.data
}

/**
 * @name 取得該月單一作品的閱覽累積數、閱覽變化量、收藏累積數、收藏變化量
 * @param titleId 作品 id
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_monthlyStats_titles__titleId_
 */
export const getMonthlyStatsByTitleId = async ({
  titleId,
  startDate,
  endDate,
}: Pick<Title, 'titleId'> & DateRange): Promise<MonthlyStats> => {
  const res = await axios({
    method: 'GET',
    url: `${endpoints.MONTHLY_STATS_TITLES}/${titleId}`,
    params: { startDate, endDate },
  })
  return res.data
}

/**
 * @name 取得所有作品的總覽數據（歷史閱覽數、歷史收藏數、累計讀角券使用數）
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_totalStats_totalOveralls
 */
export const getTotalStatsTotalOveralls = async (): Promise<TotalStats> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.TOTAL_STATS_TOTAL_OVERALLS,
  })
  return res.data
}

/**
 * @name 取得單一作品的總覽數據（歷史閱覽數、歷史收藏數、累計讀角券使用數）
 * @param titleId 作品 id
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_totalStats_titleOveralls__titleId_
 */
export const getTotalStatsTitleOveralls = async ({
  titleId,
}: Pick<Title, 'titleId'>): Promise<TotalStats> => {
  const res = await axios({
    method: 'GET',
    url: `${endpoints.TOTAL_STATS_TITLE_OVERALLS}/${titleId}`,
  })
  return res.data
}
