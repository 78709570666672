import { DailyRevenue, WithdrawalInformation, WithdrawalRecord } from '@/api/types'
import endpoints from '@/constants/endpoints'
import axios from 'axios'

/**
 * @name 收益提領資訊
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#//get_v2_creator_withdrawable
 * @description
 * 帳面可提領金額、點數收益、廣告收益、請款資料認證狀態
 */
export const getWithdrawalInformation = async (): Promise<WithdrawalInformation> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.WITHDRAWABLE,
  })
  return res.data
}

/**
 * @name 請款紀錄
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_withdrawals
 */
export const getWithdrawalRecords = async (): Promise<WithdrawalRecord[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.WITHDRAWALS,
  })
  return res.data
}

/**
 * @name 申請請款
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/post_v2_creator_withdrawals
 */
export const createWithdrawal = async (): Promise<unknown> => {
  const res = await axios({
    method: 'POST',
    url: endpoints.WITHDRAWALS,
  })
  return res.data
}

/**
 * @name 收益報表
 * @link https://api.dev.kadokado.me/v2/swagger/index.html#/Creator/get_v2_creator_revenueReport
 */
export const getRevenueReport = async (params: {
  startDate: string
  endDate: string
}): Promise<DailyRevenue[]> => {
  const res = await axios({
    method: 'GET',
    url: endpoints.REVENUE_REPORT,
    params,
  })
  return res.data
}
