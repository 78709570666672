/** @jsxImportSource theme-ui */
import useLockBodyScroll from '@/hooks/useLockBodyScroll'
import { pxToRem } from '@/utils'
import { memo, ReactNode, useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import { animated, useTransition } from 'react-spring'
import { Box, Text, ThemeUIStyleObject } from 'theme-ui'
import withPortal from './withPortal'

interface Props {
  children: ReactNode
  title: string
  isShow: boolean
  handleOnRest?: (...args: any) => any
  containerStyle?: ThemeUIStyleObject
}

type CloseType =
  | { isClosable?: true; handleClose: () => void }
  | { isClosable?: false; handleClose?: undefined }

const AnimatedBox = animated(Box)

const DialogContainer = ({
  children,
  title,
  isShow,
  isClosable = true,
  handleClose = () => {},
  handleOnRest,
  containerStyle = {},
}: Props & CloseType) => {
  const transition = useTransition(isShow, {
    from: { opacity: 0, transform: `translate3d(0, ${pxToRem(30)}, 0)` },
    enter: { opacity: 1, transform: `translate3d(0, ${pxToRem(0)}, 0)` },
    leave: { opacity: 0, transform: `translate3d(0, ${pxToRem(30)}, 0)` },
    onRest: handleOnRest,
  })

  useLockBodyScroll(isShow)

  return transition(
    ({ opacity, transform }, isShow) =>
      isShow && (
        <AnimatedBox
          px={3}
          variant="dialog"
          sx={{ variant: 'superCenter', ...containerStyle }}
          style={{ opacity }}
        >
          {isClosable && (
            <Box
              variant="superCenter"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                width: 'full',
                height: pxToRem(64),
                justifyContent: 'flex-end',
                pointerEvents: 'none',
              }}
            >
              <Box variant="superCenter" sx={{ width: pxToRem(48), height: pxToRem(48) }}>
                <FiX sx={{ color: 'gray.0', fontSize: 4 }} />
              </Box>
            </Box>
          )}
          <AnimatedBox
            px={3}
            pb={3}
            sx={{
              width: 'full',
              maxWidth: pxToRem(512),
              borderRadius: 'middle',
              backgroundColor: 'background',
            }}
            style={{ opacity, transform }}
          >
            <Box variant="superCenter" sx={{ height: pxToRem(64) }}>
              <Text variant="typography.headline.16" sx={{ variant: 'singleLineTruncation' }}>
                {title}
              </Text>
            </Box>
            {children}
          </AnimatedBox>
          <AnimatedBox
            variant="dimmer"
            sx={{ cursor: isClosable ? 'pointer' : 'auto' }}
            onClick={handleClose}
            style={{ opacity }}
          />
        </AnimatedBox>
      )
  )
}

export default withPortal(memo(DialogContainer), '#dialog__portal')
